<template>
  <div class="businessCircleApply">
    <div class="businessCircleApply-c">
      <div class="item" @click="selectType">
        <div class="item-l">商户类型：</div>
        <div class="item-r">
          {{
            form.businessType ? businessTypeopsMap[form.businessType] : "请选择"
          }}
        </div>
      </div>
      <div class="p" v-if="form.businessType == 1">
        <div class="p-l">商户营业执照：</div>
        <div class="p-r">
          <v-upload
            class="oItem-b_upload"
            :imgUrl.sync="form.businessLicense1"
            :activityPicture.sync="form.businessLicense"
            :maxCount="1"
            ref="load"
          ></v-upload>
        </div>
      </div>
      <template
        v-if="
          form.businessType == 2 &&
          (userInfo.realNameAuditStatus == '-1' ||
            userInfo.realNameAuditStatus == 2)
        "
      >
        <div class="item">
          <div class="item-l">真实姓名：</div>
          <div class="item-r">
            <v-input
              v-model="form.realName"
              :maxlength="10000"
              placeholder="请填写"
            ></v-input>
          </div>
        </div>
        <div class="item" @click="selectIdenty">
          <div class="item-l">证件类型：</div>
          <div class="item-r">{{ form.idCardType }}</div>
        </div>
        <div class="item">
          <div class="item-l">证件号码：</div>
          <div class="item-r">
            <v-input
              v-model="form.idCardNum"
              :maxlength="10000"
              placeholder="请填写"
            ></v-input>
          </div>
        </div>
        <div class="imgIdenty">
          <div class="imgIdenty-t">证件照上传：</div>
          <div class="imgIdenty-c">
            <div class="img">
              <v-upload
                class="oItem-b_upload"
                :imgUrl.sync="form.idCardPhotoFront1"
                :activityPicture.sync="form.idCardPhotoFront"
                :maxCount="2"
                ref="load"
              ></v-upload>
            </div>
            <div class="img img1">
              <v-upload
                class="oItem-b_upload"
                :imgUrl.sync="form.idCardPhotoBack1"
                :activityPicture.sync="form.idCardPhotoBack"
                :maxCount="2"
                ref="load"
              ></v-upload>
            </div>
          </div>
          <div class="imgIdenty-b">
            <div class="img"><img src="./img/zhuyi.png" alt="" /></div>
            <div class="txt">
              拍摄时确保证件<span>边框完整，字体清晰，亮度均</span>
            </div>
          </div>
        </div>
      </template>
      <div class="item">
        <div class="item-l">商户名称：</div>
        <div class="item-r">
          <v-input
            v-model="form.title"
            :maxlength="10000"
            placeholder="请填写商户名称"
          ></v-input>
        </div>
      </div>
      <div class="item" @click="selectBusType">
        <div class="item-l">经营类型：</div>
        <div class="item-r">
          {{
            form.manageType != null
              ? manageTypeopsMap[form.manageType]
              : "请选择"
          }}
        </div>
      </div>
      <div class="item">
        <div class="item-l">商户手机号：</div>
        <div class="item-r">
          <v-input
            v-model="form.mobileNum"
            :maxlength="30"
            placeholder="请填写商户手机号"
            type="number"
          ></v-input>
        </div>
      </div>
      <div class="item address">
        <div class="item-l">商户地址：</div>
        <div class="item-r pr20">
          <!-- <v-input
            v-model="form.detailedAddress"
            :maxlength="10000"
            placeholder="请填写商户地址"
            disabled
          ></v-input> -->
          <div class="place">
            {{ form.detailedAddress || "请编辑用户地址" }}
          </div>
        </div>
        <div class="editBtn" @click="gotoEdit">编辑</div>
      </div>
      <div class="item">
        <div class="item-l">营业时间：</div>
        <div class="item-r">
          <v-input
            v-model="form.openingHours"
            :maxlength="10000"
            placeholder="请填入"
          ></v-input>
        </div>
      </div>
      <div class="item" style="border: none">
        <div class="item-l">店铺封面图：</div>
      </div>
      <v-upload
        class="oItem-b_upload"
        :imgUrl.sync="form.imageUrl"
        :activityPicture.sync="form.imageUrl"
        :maxCount="1"
        ref="load"
      ></v-upload>
      <div class="item" style="border: none">
        <div class="item-l">商铺活动：</div>
      </div>
      <van-field
        style="
          background: rgba(0, 0, 0, 0.04);
          height: 100%;
          border-radius: 16px;
        "
        v-model="form.discountInfo"
        type="textarea"
        maxlength="200"
        autosize
        :row="5"
        placeholder="请输入活动内容"
        show-word-limit
      />
      <div class="btn" @click="postApply">提交</div>
    </div>
    <v-picker
      :columns="businessTypeops"
      :valueShow="showPicker"
      :valueKey="'text'"
      @clickOverlay="clickPickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirmPick"
    ></v-picker>
    <v-picker
      :columns="manageTypeops"
      :valueShow="showPicker1"
      :valueKey="'text'"
      @clickOverlay="clickPickOverlay1"
      @cancel="cancelPick1"
      @confirmPicker="confirmPick1"
    ></v-picker>
    <v-picker
      :columns="idCardTypeList"
      :valueShow="showPicker2"
      :valueKey="'text'"
      @clickOverlay="clickPickOverlay2"
      @cancel="cancelPick2"
      @confirmPicker="confirmPick2"
    ></v-picker>
  </div>
</template>

<script>
import {
  getBusinessCircleStatusUrl,
  getMyBusinessUrl,
  postApplyUrl,
  getInitInfoUrl,
  userInfoUrl,
} from "./api.js";
import {
  businessTypeopsMap,
  businessTypeops,
  manageTypeopsMap,
  manageTypeops,
} from "./map.js";
var moment = require("moment");
export default {
  name: "businessCircleApply",
  data() {
    return {
      businessTypeops,
      manageTypeops,
      businessTypeopsMap,
      manageTypeopsMap,
      showPicker2: false,
      showPicker: false,
      showPicker1: false,
      userInfo: {},
      identyInfo: {},
      idCardTypeList: [],
      isEdit: "",
      form: {
        idCardPhotoBack: "",
        idCardPhotoBack1: "",
        idCardPhotoFront: "",
        idCardPhotoFront1: "",
        idCardNum: "",
        realName: "",
        idCardType: "请选择",
        businessType: "",
        title: "",
        manageType: null,
        mobileNum: "",
        detailedAddress: "",
        openingHours: "",
        businessLicense: "",
        businessLicense1: "",
        imageUrl: "",
        discountInfo: "",
        houseId: "",
      },
    };
  },
  created() {
    this.getSessionStorageData();
    this.isEdit = this.$route.query.isEdit;
    if (this.isEdit == 1) {
      this.getMyBusiness();
    } else {
      if (this.$route.query.detailedAddress) {
        this.form.detailedAddress = this.$route.query.detailedAddress;
      }
      if (this.$route.query.houseId) {
        this.form.houseId = this.$route.query.houseId;
      }
    }
    this.getUserInfo();
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    openId() {
      return this.$store.state.openId;
    },
  },
  methods: {
    gotoEdit() {
      sessionStorage.setItem("form", JSON.stringify(this.form));
      this.$router.replace({
        name: "businessMap",
        query: {
          isEdit: this.isEdit,
        },
      });
    },
    getSessionStorageData() {
      if (JSON.parse(sessionStorage.getItem("form"))) {
        this.form.businessType = JSON.parse(
          sessionStorage.getItem("form")
        ).businessType;
        this.form.businessLicense1 = JSON.parse(
          sessionStorage.getItem("form")
        ).businessLicense;
        this.form.businessLicense = JSON.parse(
          sessionStorage.getItem("form")
        ).businessLicense;
        this.form.idCardPhotoFront = JSON.parse(
          sessionStorage.getItem("form")
        ).idCardPhotoFront;
        this.form.idCardPhotoFront1 = JSON.parse(
          sessionStorage.getItem("form")
        ).idCardPhotoFront1;
        this.form.idCardNum = JSON.parse(
          sessionStorage.getItem("form")
        ).idCardNum;
        this.form.idCardPhotoBack = JSON.parse(
          sessionStorage.getItem("form")
        ).idCardPhotoBack;
        this.form.idCardPhotoBack1 = JSON.parse(
          sessionStorage.getItem("form")
        ).idCardPhotoBack1;
        this.form.title = JSON.parse(sessionStorage.getItem("form")).title;
        this.form.manageType = JSON.parse(
          sessionStorage.getItem("form")
        ).manageType;
        this.form.mobileNum = JSON.parse(
          sessionStorage.getItem("form")
        ).mobileNum;
        this.form.openingHours = JSON.parse(
          sessionStorage.getItem("form")
        ).openingHours;
        this.form.imageUrl = JSON.parse(
          sessionStorage.getItem("form")
        ).imageUrl;
        this.form.discountInfo = JSON.parse(
          sessionStorage.getItem("form")
        ).discountInfo;
      }
    },
    async getMyBusiness() {
      let params = {
        tenantId: this.tenantId,
        houseId: this.roomId || this.communityId || undefined,
        userId: this.userId,
      };

      let res = await this.$axios.get(`${getMyBusinessUrl}`, {
        params,
      });
      if (res.code == 200) {
        this.form = res.data;
        if (this.form.businessLicense) {
          this.form.businessLicense1 = this.form.businessLicense;
        }
        if (this.$route.query.detailedAddress) {
          this.form.detailedAddress = this.$route.query.detailedAddress;
        }
        if (this.$route.query.houseId) {
          this.form.houseId = this.$route.query.houseId;
        }
      }
    },
    selectIdenty() {
      this.showPicker2 = true;
    },
    async postData() {
      let params = {
        id: this.form.id,
        idCardPhotoBack: this.form.idCardPhotoBack,
        idCardPhotoFront: this.form.idCardPhotoFront,
        idCardNum: this.form.idCardNum,
        realName: this.form.realName,
        idCardType: this.form.idCardType,
        businessType: this.form.businessType,
        title: this.form.title,
        manageType: this.form.manageType,
        mobileNum: this.form.mobileNum,
        detailedAddress: this.form.detailedAddress,
        openingHours: this.form.openingHours,
        businessLicense: this.form.businessLicense,
        userId: this.userId,
        tenantId: this.tenantId,
        // houseId: this.houseId || undefined,
        nickName: this.userInfo.nickname,
        imageUrl: this.form.imageUrl,
        discountInfo: this.form.discountInfo,
        houseId: this.tenantId,
        latitude: localStorage.getItem("latitude"),
        longitude: localStorage.getItem("longitude"),
      };
      let res = await this.$axios.post(`${postApplyUrl}`, params);
      if (res.code === 200) {
        this.$toast("提交成功");
        sessionStorage.removeItem("form");
        this.$router.go(-1);
        // this.$router.push({
        //   name: "businessCircle",
        // });
      }
    },
    postApply() {
      if (!this.form.businessType) {
        this.$toast("请选择商户类型");
        return;
      }
      if (this.form.businessType == 1) {
        if (!this.form.businessLicense) {
          this.$toast("请上传营业执照");
          return;
        }
      }
      if (
        this.form.businessType == 2 &&
        (this.userInfo.realNameAuditStatus == "-1" ||
          this.userInfo.realNameAuditStatus == 2)
      ) {
        if (!this.form.realName) {
          this.$toast("请填写真名");
          return;
        }
        if (!this.form.idCardType || this.form.idCardType == "请选择") {
          this.$toast("请选择证件类型");
          return;
        }
        if (!this.form.idCardNum) {
          this.$toast("请填写证件号");
          return;
        }
        if (!this.form.idCardPhotoFront) {
          this.$toast("请上传证件正面");
          return;
        }
        if (!this.form.idCardPhotoBack) {
          this.$toast("请上传证件反面");
          return;
        }
      }
      if (!this.form.title) {
        this.$toast("请填写商户名称");
        return;
      }
      if (!this.form.manageType && this.form.manageType != 0) {
        this.$toast("请选择经营类型");
        return;
      }
      if (!this.form.mobileNum) {
        this.$toast("请填写手机号");
        return;
      }
      if (!this.form.detailedAddress) {
        this.$toast("请填写商户地址");
        return;
      }
      if (!this.form.openingHours) {
        this.$toast("请填写营业时间");
        return;
      }
      if (!this.form.imageUrl) {
        this.$toast("请上传店铺封面图");
        return;
      }
      if (this.form.discountInfo.length == 0) {
        this.$toast("请输入活动内容");
        return;
      }

      this.postData();
    },
    async getInitInfoUrl() {
      let params = {
        tenantId: this.tenantId,
      };
      let res = await this.$axios.get(`${getInitInfoUrl}`, {
        params,
      });
      this.idCardTypeList = [];
      if (res.code === 200) {
        if (res.data && res.data.idCardTypeList) {
          res.data.idCardTypeList.forEach((ele) => {
            let obj = {};
            obj.value = ele.dictKey;
            obj.text = ele.dictValue;
            this.idCardTypeList.push(obj);
          });
        }
      }
    },
    async getUserInfo() {
      let params = {
        userId: this.userId,
      };
      let res = await this.$axios.get(`${userInfoUrl}`, {
        params,
      });
      if (res.code === 200) {
        this.userInfo = res.data;
      }
    },
    selectBusType() {
      this.showPicker1 = true;
    },
    clickPickOverlay2() {
      this.showPicker2 = false;
    },
    cancelPick2() {
      this.showPicker2 = false;
    },
    confirmPick2(oValue) {
      this.form.idCardType = oValue.value;
      this.showPicker2 = false;
    },
    clickPickOverlay1() {
      this.showPicker1 = false;
    },
    cancelPick1() {
      this.showPicker1 = false;
    },
    confirmPick1(oValue) {
      this.form.manageType = oValue.value;
      this.showPicker1 = false;
    },
    selectType() {
      this.showPicker = true;
    },
    clickPickOverlay() {
      this.showPicker = false;
    },
    cancelPick() {
      this.showPicker = false;
    },
    init() {
      this.form.businessLicense = "";
      this.form.businessLicense1 = "";
    },
    confirmPick(oValue) {
      this.form.businessType = oValue.value;
      this.showPicker = false;
      if (this.form.businessType == 2) {
        this.getInitInfoUrl();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.businessCircleApply {
  padding: 0 42px;
  box-sizing: border-box;
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 10px 28px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);
    .item-l {
      width: 200px;
      font-size: 32px;
      font-weight: 400;
      color: #333333;
      line-height: 44px;
    }
    .item-r {
      flex: 1;
      text-align: right;
      height: 44px;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.5);
      /deep/ .van-field {
        height: 44px;
        padding: 0;
        input {
          text-align: right;
        }
      }
      .place {
        width: 100%;
        height: 100%;
        // line-height: 44px;
      }
    }
    .pr20 {
      padding-right: 10px;
    }
    .editBtn {
      width: 100px;
      height: 44px;
      background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
      text-align: center;
      font-size: 24px;
      border-radius: 15px;
      color: #ffffff;
      font-weight: 600;
      line-height: 44px;
      box-sizing: border-box;
    }
  }
  .address {
    .item-r {
      height: auto;
      text-align: left;
    }
  }
  .p {
    padding: 0 10px;
    .p-l {
      padding: 40px 0 28px;
      font-size: 32px;
      font-weight: 400;
      color: #333333;
      line-height: 44px;
    }
  }
  .imgIdenty {
    padding: 0 10px;
    .imgIdenty-t {
      padding: 40px 0 28px;
      font-size: 32px;
      font-weight: 400;
      color: #333333;
      line-height: 44px;
    }
    .imgIdenty-c {
      display: flex;
      justify-content: space-between;
      margin: 0 0 34px;
      .img {
        width: 280px;
        height: 193px;
        overflow: hidden;
      }
      /deep/ .v-upload {
        width: 100%;
        height: 100%;
        .van-uploader {
          width: 100%;
          height: 100%;
          .van-uploader__wrapper {
            width: 100%;
            height: 100%;
            .van-uploader__preview {
              width: 100%;
              height: 100%;
              .van-uploader__preview-image {
                width: 100%;
                height: 100%;
              }
            }
            .van-uploader__upload {
              width: 100%;
              height: 100%;
              background: url("./img/shenfenzheng.png") no-repeat;
              background-size: 100% 100%;
              i {
                display: none;
              }
            }
          }
        }
      }
      .img1 {
        /deep/ .v-upload {
          .van-uploader {
            .van-uploader__wrapper {
              .van-uploader__upload {
                background: url("./img/shenfen.png") no-repeat;
                background-size: 100% 100%;
              }
            }
          }
        }
      }
    }
    .imgIdenty-b {
      display: flex;
      align-items: center;
      .img {
        width: 28px;
        height: 28px;
        margin-right: 16px;
        font-size: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .txt {
        font-size: 24px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.25);
        line-height: 34px;
        span {
          color: rgba(250, 180, 40, 1);
        }
      }
    }
  }
  .btn {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #ffc055 0%, #fe9514 100%);
    border-radius: 10px;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
    line-height: 66px;
    margin: 100px auto;
  }
}
</style>
